@label-color: #676c77;
@inactive-tag-background: #e5e8ed;
@active-tag-color: #d1fae5;
@active-tag-text-color: #065f46;
@placeholder-color: #aeb3bb;

.organization {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

#organization-add-search {
  .search-component {
    margin-right: 16px;
  }
}
#add-search-organization {
  .organization-search {
    margin-right: 5px;
  }
}
.organization-content {
  padding: 0 16px 16px;

  .organization-table {
    .ant-table-thead > tr > th,
    .ant-table-cell .ant-table-column-has-sorters {
      background: @white-color;
      color: @grey-color;
      font-weight: 500;
      padding: 16px 24px;
    }
    .organization-card {
      height: calc(100vh - 187px);
      overflow-y: auto;
      .ant-card-bordered {
        margin-bottom: 10px;
      }
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          margin: 0px;
          font-size: 18px;
        }
        span {
          font-size: 12px;
        }
      }
      .ant-btn {
        height: 34px;
        margin-right: 10px;
        padding: 0px;
      }
      .card-content {
        p {
          margin: 0;
          margin-bottom: 2px;
          color: @label-color;
          line-height: 1.3;
        }
      }
    }
  }
}

.add-organization-header {
  background: @light-white-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px;
  position: sticky;
  top: 0;
  z-index: 1020;

  & svg {
    margin-bottom: 15px;
    margin-right: 10px;
  }
}
.form-content {
  @media screen and (max-width: @screen-xs-max) {
    padding: 0 (@layout-main-content-padding-res + 5px)
      (@layout-main-content-padding-res + 5px);
  }
  padding: 0px 24px 24px 24px;
}
.organization-form {
  display: flex;
  justify-content: center;
  .add-organization-form {
    width: 100%;
    .ant-row.add-organization-form {
      display: flex;
      justify-content: flex-start;
    }
    .add-organization-content {
      width: 100%;
      padding: 20px 0px;
      .border-radius(@border-radius-lg);
      position: relative;
      height: 100%;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: left;
      h2 {
        font-weight: 500;
        color: @light-navy-blue;
      }
      .organization-details,
      .owner-details {
        margin-bottom: 20px;
      }
      .ant-form-item-control-input {
        .ant-form-item-control-input-content > input {
          line-height: 1.3;
          height: 32px;
        }
      }
      .ant-select-selector {
        height: 36px;
        background: #f1f3f7;
        input {
          height: 100%;
        }
      }
      .addon-div {
        background: #f1f3f7;
        padding: 4px 8px;
        border-radius: 6px;
        align-items: center;
        color: @grey-10;
        line-height: 1.5;
        .ant-switch-checked {
          background: #202a3f;
        }
        .ant-row,
        .ant-form-item {
          margin: 0;
        }
      }
      .font-weight-500 {
        font-weight: 500;
      }
      .m-5 {
        margin: 5px;
      }
      .sub-addon-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 20px;
        .input-number {
          width: 60px;
          input {
            background-color: @white-color;
          }
        }
      }
      .register-limit-input {
        width: 100px;
      }
      .number-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid #cdd5e0;
        border-radius: 71px;
        &.ant-btn-circle {
          min-width: 25px;
          height: 25px;
        }
      }
      .ant-input,
      .ant-select:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-input-number-input,
      .ant-picker {
        background: #f1f3f7;
      }

      .ant-card {
        border: 1px solid #e8e8e8;
        .ant-card-body {
          h1 {
            margin-top: 0;
            margin-bottom: 14px;
          }
          .plan-details {
            h3 {
              margin-top: 20px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
    & label {
      font-size: 14px;
    }
    & input {
      &::placeholder {
        color: @placeholder-color;
      }
    }
    & .ant-select {
      &-selection-item {
        color: @grey-color;
      }
    }

    & .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
.ant-picker {
  width: 100%;
}

.ant-tag {
  border-radius: 10px;
}

.inactive-tag {
  background: @inactive-tag-background;
  color: @label-color;
  font-weight: 500;
}

.active-tag {
  background: @active-tag-color;
  color: @active-tag-text-color;
  font-weight: 500;
  font-size: 12px;
}

.ant-table .ant-table-tbody .ant-btn {
  height: 34px;
  margin-right: 10px;
  padding: 0px;
}
.action {
  margin-left: auto;
  .edit-button-disabled {
    svg {
      path {
        fill: #adb0b7;
      }
    }
  }
}

.details-content {
  padding: 20px 20px;
  @media screen and (max-width: @screen-xs-max) {
    padding: 0 16px 16px;
  }
  .ant-tabs-ink-bar {
    background: @primary-color;
  }

  .ant-tabs-nav {
    margin: 0;
    border: none;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin: 0;
    }
  }

  .ant-tabs-tabpane {
    // padding: 20px 20px;
    @media screen and (max-width: @screen-md-max) {
      height: max-content;
    }
    .ant-descriptions-item-label {
      font-size: @font-size-base - 2px;
      color: @grey-color;
    }
    .ant-descriptions-item-content {
      color: @light-navy-blue;
    }
    .addon-div {
      background: #f1f3f7;
      padding: 4px 8px;
      border-radius: 6px;
      color: #676c77;
      .ant-switch-checked {
        background: #202a3f;
      }
    }
    .font-weight-500 {
      font-weight: 500;
    }
    .m-5 {
      margin: 5px;
    }
    .sub-addon-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 20px;
      min-height: 36px;
    }
  }
  .ant-tabs-tab {
    width: 96px;
    display: flex;
    margin: 0;
    border-radius: 4px;
    justify-content: center;
    background: #e5e8ed;
    .ant-tabs-tab-btn {
      font-weight: 500;
    }
    &-active {
      background: @white-color;
      color: @primary-color;
      .ant-tabs-tab-btn {
        color: @primary-color;
        font-weight: 500;
      }
    }
    &:hover {
      color: @primary-color;
    }
  }

  & .ant-tabs-tabpane {
    // background: @white-color;
    border-radius: 0px 8px 8px 8px;
  }
  .details-tab {
    background-color: transparent;
    padding: 0px;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
}

.user-details {
  .ant-table-thead > tr > th {
    color: @grey-color;
  }
  .users-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &-title {
      margin: 0;
    }
    & .ant-btn-round {
      background: @dark-orange-color;
      border-radius: 40px;
      border: @dark-orange-color;
      font-weight: 500;
      font-size: @font-size-base - 2px;
    }
  }
  .user-table {
    margin-top: 15px;
    .ant-table-thead > tr > th,
    .ant-table-cell .ant-table-column-has-sorters {
      background: @white-color;
      font-weight: 500;
      padding: 16px 24px;
    }
    .ant-pagination {
      margin-bottom: 0;
    }
  }
}

.users-card {
  height: calc(100vh - 187px);
  overflow-y: auto;
  margin-top: 15px;
  width: 100%;
  .ant-card-bordered {
    margin-bottom: 10px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-btn.ant-btn-round.ant-btn {
    height: 34px;
    margin-right: 10px;
    padding: 0px;
  }
}

.ant-table-wrapper tr > td.ant-table-cell {
  color: #222222;
  background: @white-color;
  padding: 16px 24px;
  .ant-tag {
    font-size: 12px;
  }
}

.details {
  h1 {
    font-weight: 500;
    font-size: @h2-font-size;
    margin: 0;
  }
  p {
    margin: 0;
  }
}
.organization-details {
  h1 {
    font-weight: 500;
    font-size: @h2-font-size;
    margin-bottom: 24px;
    margin-top: 0;
  }
}

.ant-card-body {
  padding: 12px;
}

.details {
  _ .details-header {
    @media screen and (max-width: @screen-md-max) {
      margin-left: 25px;
    }
  }
}

.details-content-organization,
.details-content-subscription {
  @media screen and (max-width: 1000px) {
    th.ant-descriptions-item {
      padding: 0;
      .ant-descriptions-item-label {
        font-size: @font-size-base - 4px;
        color: @grey-color;
      }
    }
  }
}
.custom-date-picker {
  background: @white-color;
  border-radius: 40px;
  box-shadow: none;
  width: 245px;
  height: 32px;
  .ant-picker-active-bar {
    background: none;
  }
  svg {
    width: 25px;
    fill: @primary-color;
    height: 25px;
    margin-right: 0px;
  }
  input {
    line-height: 1;
  }
}
