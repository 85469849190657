.users {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .user-details {
    width: 100%;
    padding: 20px;
    background: white;
  }
}
.users-content {
  padding: 0 16px 16px;
  .users-search {
    margin-bottom: 20px;
  }

  .users-table {
    .ant-table-thead > tr > th {
      background: @white-color;
      color: @grey-color;
      font-weight: 500;
      padding: 16px 24px;
    }
  }
}

.users-card {
  overflow-y: auto;
  width: 100%;
  .ant-card-bordered {
    margin-bottom: 10px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h1 {
      font-size: 18px;
      margin: 0;
      margin-bottom: 8px;
    }
  }
  .card-content p {
    margin-bottom: 0;
    margin: 0;
    color: @grey-color;
  }
}
