*,::after,::before {
  font-family: 'Rubik', sans-serif;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #676c77;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #f2f4f7;
  font-feature-settings: 'tnum', 'tnum';
}

code {
  font-family: 'Rubik', sans-serif;
}
