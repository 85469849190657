/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*
Author         : G-axon
Template Name  : Wieldy Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles
        05. Flex Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Navbar(Ant Menu) Style
        05. Footer Styles
        06. Customizer Style
        07. Drawer Style

    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. BackTop Styles
        04. Badges Styles
        05. Button Styles
        06. Cards Styles
        07. Cascader Styles
        08. Chart Styles
        09. Form Styles
        10. Icon Styles
        11. Input Styles
        12. List Styles
        13. Loader Styles
        14. Pagination Styles
        15. Pickers Styles
        16. Progress Styles
        17. Slider Styles
        18. Steps Styles
        19. Tables Styles
        20. Tabs Styles
        21. Time Lines Style

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style
        06. Profile App Style
        07. Wall App Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles
        09. Algolia Styles

  =============================================================*/
/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 22px;
}
.framed-layout h1,
.framed-layout .h1 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .framed-layout h1,
  .framed-layout .h1 {
    font-size: 18px;
  }
}
.boxed-layout h1,
.boxed-layout .h1 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 18px;
  }
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.gx-h1-lg {
  font-size: 28px;
}
.gx-text-strikethrough {
  text-decoration: line-through !important;
}
.gx-text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.gx-fs-xxs {
  font-size: 8px;
}
/*10px*/
.gx-fs-xs {
  font-size: 10px;
}
/*11px*/
.gx-fs-11 {
  font-size: 11px;
}
/*12px*/
.gx-fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.gx-fs-md {
  font-size: 16px;
  line-height: 1;
}
/*16px*/
.gx-fs-lg {
  font-size: 18px;
  line-height: 1;
}
/*20px*/
.gx-fs-xl {
  font-size: 22px;
  line-height: 1;
}
/*24px*/
.gx-fs-xxl {
  font-size: 26px;
  line-height: 1.3;
}
@media screen and (max-width: 1999px) {
  .gx-fs-xxl {
    font-size: 22px;
  }
}
/*28px*/
.gx-fs-2xl {
  font-size: 32px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-2xl {
    font-size: 24px !important;
  }
}
/*30px*/
.gx-fs-xxxl {
  font-size: 34px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-xxxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 1999px) {
  .gx-fs-xxxl {
    font-size: 30px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xxxl {
    font-size: 28px;
  }
}
/*40px*/
.gx-fs-xlxl {
  font-size: 44px;
  line-height: 1;
}
@media screen and (max-width: 1999px) {
  .gx-fs-xlxl {
    font-size: 36px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xlxl {
    font-size: 32px;
  }
}
/*50px*/
.gx-fs-icon-lg {
  font-size: 56px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-icon-lg {
    font-size: 42px;
  }
}
@media screen and (max-width: 575px) {
  .gx-fs-icon-lg {
    font-size: 34px;
  }
}
/*54px*/
.gx-fs-iconcard {
  font-size: 60px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-iconcard {
    font-size: 38px;
  }
}
.gx-text-lowercase {
  text-transform: lowercase;
}
.gx-text-uppercase {
  text-transform: uppercase;
}
.gx-text-capitalize {
  text-transform: capitalize;
}
.gx-font-weight-light {
  font-weight: 300;
}
.gx-font-weight-normal {
  font-weight: 400;
}
.gx-font-weight-medium {
  font-weight: 500;
}
.gx-font-weight-semi-bold {
  font-weight: 600;
}
.gx-font-weight-bold {
  font-weight: 700;
}
.gx-font-weight-heavy {
  font-weight: 900;
}
.gx-font-italic {
  font-style: italic;
}
.gx-letter-spacing-base {
  letter-spacing: 3px;
}
.gx-letter-spacing-lg {
  letter-spacing: 6px;
}
.gx-letter-spacing-xl {
  letter-spacing: 8px;
}
.gx-text-justify {
  text-align: justify !important;
}
.gx-text-nowrap {
  white-space: nowrap !important;
}
.gx-text-left {
  text-align: left !important;
}
.gx-text-right {
  text-align: right !important;
}
.gx-text-center {
  text-align: center !important;
}
.gx-text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f2f4f7;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f2f4f7;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f2f4f7;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content-wrapper {
  flex: 1;
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
/* Header Style */
.ant-layout-header {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px 16px;
}
.ant-layout-header h1 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
}
@media screen and (max-width: 991px) {
  .ant-layout-header h1 {
    margin-left: 5px;
  }
}
.ant-layout-header h1 svg {
  margin-right: 10px;
  height: 24px;
  width: 24px;
}
.ant-layout-header .ant-btn-round {
  background: #fc5027;
  border-radius: 40px;
  border: #fc5027;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.gx-nav-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
  line-height: 50px;
}
.gx-nav-header-below {
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #ffffff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #676c77;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e8e8e8;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #fa8c15;
  background-color: #ffffff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #003366;
  color: #fa8c15;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  -webkit-border-radius: 0 0 135px 135px;
  -moz-border-radius: 0 0 135px 135px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1849px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1999px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 16px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1999px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #8e929d;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #fa8c15;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e8e8e8;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e8e8e8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 32px 13px 79px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: var(--ant-green-6);
  color: var(--ant-green-6);
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 32px;
  top: 15px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
.header-logo {
  font-size: x-large;
}
.header-menu-items ul {
  font-size: large;
}
.header-menu-items ul svg {
  font-size: large;
}
.header-left-content-h1-svg {
  margin-right: 10px;
}
@media screen and (max-width: 991px) {
  h1.portal-header {
    margin-left: 5px;
  }
}
.custom-header .ant-menu > li.ant-menu-item {
  margin-top: -4px;
  color: #676c77;
  border-bottom: 4px solid transparent;
  font-size: 18px;
}
.custom-header .ant-menu > li.ant-menu-item svg {
  fill: #ffffff;
}
.custom-header .ant-menu > li.ant-menu-item:hover {
  color: #fc5027;
  border-bottom: 4px solid #fc5027;
}
.custom-header .ant-menu > li.ant-menu-item:hover svg {
  fill: #ffffff;
}
.custom-header .ant-menu > li.ant-menu-item-selected {
  border-bottom: 4px solid #fc5027;
  color: #fc5027;
}
.custom-header .ant-menu > li.ant-menu-item-selected svg {
  fill: #ffffff;
}
.custom-header .ant-menu > li.ant-menu-item-selected:hover {
  color: #fc5027;
  border-bottom: 4px solid #fc5027;
}
.custom-header .ant-menu > li.ant-menu-item-selected:hover svg {
  fill: #ffffff;
}
.header-logo {
  display: flex;
  align-items: center;
  height: 70px;
}
.header-logo .gx-site-logo {
  height: 70px;
}
.header-logo svg {
  width: 150px;
  height: 70px;
}
.header-right li.ant-menu-submenu.ant-menu-submenu-horizontal {
  border-bottom: none;
  margin: 0;
}
.header-right li.ant-menu-submenu.ant-menu-submenu-horizontal:hover {
  border-bottom: none;
}
.header-right .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.header-right .ant-menu-submenu-title svg {
  margin-right: 16px;
}
.title-bar {
  background-color: #f2f4f7;
  height: 52px;
  line-height: 50px;
}
#nav-bar-dropdown .ant-dropdown {
  top: 51px !important;
  width: 100%;
}
#nav-bar-dropdown .ant-dropdown .ant-dropdown-menu {
  position: relative;
  left: -15px;
  padding: 0;
}
#nav-bar-dropdown .ant-dropdown-menu {
  border-radius: 0;
  box-shadow: 0px 3px 3px -4px rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.08), 0 9px 9px 8px rgba(0, 0, 0, 0.05);
}
#nav-bar-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 32px;
  font-size: 16px;
}
@media screen and (max-width: 999px) {
  .ant-layout-header {
    line-height: 50px;
  }
  .ant-layout-header ul.ant-menu-horizontal {
    line-height: 50px;
  }
  .ant-layout-header ul.ant-menu-horizontal .ant-menu-submenu {
    margin: 0 5px;
  }
  .ant-layout-header ul.ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title {
    padding: 0;
  }
  .header-logo {
    height: 35px;
  }
  .header-logo .gx-site-logo {
    height: 35px;
  }
  .header-logo svg {
    height: 35px;
    width: 100px;
  }
  .header-logo .mobile-header-logo {
    height: 40px;
  }
  .mobile-menu-trigger {
    z-index: 10;
    margin-right: 10px;
  }
}
/* Sidebar Style */
.gx-app-sidebar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ant-layout-sider {
  color: #676c77;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 256px !important;
  overflow: hidden;
}
@media screen and (max-width: 999px) {
  .ant-layout-sider {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.28);
  }
}
@media screen and (max-width: 999px) {
  .ant-layout-sider {
    position: absolute;
    z-index: 999;
    height: 100%;
  }
}
.ant-layout-sider-collapsed {
  width: 72px !important;
}
@media screen and (max-width: 1000px) {
  .ant-layout-sider-collapsed {
    display: none;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
.ant-layout-sider .sider-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 101px);
}
@media screen and (max-width: 575px) {
  .ant-layout-sider .sider-content {
    height: calc(100% - 58px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider .sider-content {
    height: calc(100% - 83px);
  }
}
.ant-layout-sider .sider-footer {
  justify-content: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  height: 56px;
  font-size: 16px;
  border-bottom: 1px solid #797f8c;
}
.ant-layout-sider .sider-footer svg {
  margin-top: 5px;
}
.ant-layout-sider .sider-footer > .ant-menu {
  background-color: #ffffff;
}
.ant-layout-sider .sider-footer .ant-menu-submenu-title {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 380px) {
  .ant-layout-sider .sider-footer {
    margin-top: 350px;
  }
}
.ant-layout-sider .ant-layout-sider-trigger {
  position: absolute;
  background: #000000;
  height: 56px;
  max-width: 256px !important;
  width: 100% !important;
}
.ant-layout-sider .ant-layout-sider-trigger svg {
  margin-top: 15px;
}
.ant-popover-inner-content {
  justify-content: center;
  display: flex;
  padding: 10px 16px 0px;
  color: #202a3f;
}
.gx-layout-sider-dark {
  background-color: #003366;
  color: #038fdd;
}
.gx-layout-sider-header {
  justify-content: center;
  height: 72px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .gx-layout-sider-header {
    margin-left: 5px;
    justify-content: space-around;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
.gx-sidebar-content {
  border-right: 1px solid #fc5027;
  background: #202a3f;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 1px solid #fc5027;
}
.gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 145px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 206px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #fc5027;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #038fdd;
}
.gx-app-nav li {
  font-size: 22px;
}
.gx-app-nav li svg {
  cursor: pointer;
  fill: #fc5027;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #fc5027;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  display: none;
}
button.ant-btn.sider-menubar {
  background: #202a3f;
  display: block;
  position: absolute;
  left: 0;
  padding: 7px 7px;
  border-radius: 0px 8px 8px 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 99999;
  top: 10px;
}
@media screen and (max-width: 575px) {
  button.ant-btn.sider-menubar {
    top: 5px;
  }
}
button.ant-btn.sider-menuarrow {
  background: none;
  border: none;
}
@media screen and (max-width: 999px) {
  .ant-layout-sider-children {
    width: 216px;
    background: #202a3f;
  }
}
/* Ant Menu Style */
.ant-menu {
  color: #f2f4f7;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 50px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  color: #fc5027;
}
.ant-menu-item:active svg:active,
.ant-menu-submenu-title:active svg:active {
  fill: #fc5027;
}
.ant-menu-item svg {
  min-width: 16px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.ant-menu-item svg[class^='svg-']::before,
.ant-menu-item svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-item svg[class*=' svg-']:active {
  fill: #fc5027;
}
.ant-menu-sub .ant-menu-item svg {
  font-size: 12px;
}
.ant-menu-item > a:hover {
  color: #676c77;
}
.ant-menu svg {
  fill: #f2f4f7;
}
.ant-menu svg:active {
  fill: #fc5027;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  fill: #fc5027;
  color: #fc5027;
}
.ant-menu-item:hover svg:hover,
.ant-menu-item-active svg:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open svg:hover,
.ant-menu-submenu-active svg:hover,
.ant-menu-submenu-title:hover svg:hover {
  fill: #fc5027;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  fill: #676c77;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected svg {
  fill: #fc5027;
}
.ant-menu-item-selected > a:hover,
.ant-menu-item-selected svg:hover {
  color: #676c77;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #fc5027;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.gx-drawer-sidebar .ant-menu-inline,
.gx-drawer-sidebar .ant-menu-vertical,
.gx-drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-inside-header-horizontal .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a,
.ant-menu-sub > li svg {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  border-radius: 8px;
  color: #202a3f;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px;
}
.ant-menu-submenu-popup {
  border-radius: 0;
}
.gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
  display: none;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #f2f4f7, #f2f4f7);
}
.gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 60px;
  display: none;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  display: none;
  background: linear-gradient(to right, #676c77, #676c77);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #676c77;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a,
.ant-menu-vertical .ant-menu-submenu-selected svg,
.ant-menu-vertical-left .ant-menu-submenu-selected svg,
.ant-menu-vertical-right .ant-menu-submenu-selected svg {
  fill: #676c77;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 70px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #676c77;
  color: #676c77;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a,
.ant-menu-horizontal > .ant-menu-item svg,
.ant-menu-horizontal > .ant-menu-submenu svg {
  fill: #f2f4f7;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover,
.ant-menu-horizontal > .ant-menu-item svg:hover,
.ant-menu-horizontal > .ant-menu-submenu svg:hover {
  fill: #676c77;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before,
.ant-menu-horizontal > .ant-menu-item svg:before,
.ant-menu-horizontal > .ant-menu-submenu svg:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 6px solid #fc5027;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-menu-vertical .ant-menu-item svg,
.ant-menu-vertical-left .ant-menu-item svg,
.ant-menu-vertical-right .ant-menu-item svg,
.ant-menu-inline .ant-menu-item svg,
.ant-menu-vertical .ant-menu-submenu-title svg,
.ant-menu-vertical-left .ant-menu-submenu-title svg,
.ant-menu-vertical-right .ant-menu-submenu-title svg,
.ant-menu-inline .ant-menu-submenu-title svg {
  min-width: 16px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical-left .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical-right .ant-menu-item svg[class^='svg-']::before,
.ant-menu-inline .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-inline .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical-left .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical-right .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-inline .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-inline .ant-menu-submenu-title svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title svg + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px;
}
.ant-menu-inline-collapsed {
  width: 72px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 0px;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item svg + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item svg + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title svg + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip svg {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip svg {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a,
.ant-menu-item-disabled svg,
.ant-menu-submenu-disabled svg {
  fill: var(--ant-color-text-tertiary);
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #676c77;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  display: none;
  background: linear-gradient(to right, #676c77, #676c77);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #038fdd;
  background: #003366;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  display: none;
  background: #038fdd;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #003366;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #003366;
}
.ant-menu-dark .ant-menu-item svg {
  min-width: 16px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item svg[class^='svg-']::before,
.ant-menu-dark .ant-menu-item svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item > a {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a,
.ant-menu svg {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a,
.ant-menu-dark .ant-menu-item:hover svg,
.ant-menu-dark .ant-menu-item-active svg,
.ant-menu-dark .ant-menu-submenu-active svg,
.ant-menu-dark .ant-menu-submenu-selected svg,
.ant-menu-dark .ant-menu-submenu-title:hover svg,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover svg {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected svg > a:hover,
.ant-menu-dark .ant-menu-item-selected svg:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected span {
  color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled svg,
.ant-menu-dark .ant-menu-submenu-disabled svg {
  fill: var(--ant-color-text-tertiary);
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #262626;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-layout-sider .ant-menu .ant-menu-item svg,
.ant-drawer .ant-menu .ant-menu-item svg {
  font-size: 22px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item svg {
  font-size: 16px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  display: none;
  background-image: linear-gradient(to right, #676c77, #676c77);
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover,
svg:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover,
svg:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  display: none;
}
.gx-navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-navbar-nav li {
  padding: 0 5px;
}
.gx-navbar-nav li a,
.gx-navbar-nav li svg {
  display: block;
  padding: 4px 10px;
  background-color: #fc5027;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-profile-banner .gx-navbar-nav li a,
.gx-profile-banner .gx-navbar-nav li svg {
  background-color: transparent;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
.gx-navbar-nav li a *,
.gx-navbar-nav li svg * {
  color: #bfbfbf;
}
.gx-navbar-nav li a:hover,
svg:hover .gx-navbar-nav li a:focus,
.gx-navbar-nav li svg:focus {
  background-color: #ed3003;
  color: #ffffff;
}
.gx-profile-banner .gx-navbar-nav li a:hover,
.gx-profile-banner svg:hover .gx-navbar-nav li a:focus,
.gx-profile-banner .gx-navbar-nav li svg:focus {
  background-color: transparent;
  color: #fa8c15;
}
.gx-profile-banner .gx-navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav li {
    padding: 0 6px;
  }
}
.gx-profile-banner .gx-navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav {
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.gx-menu-horizontal {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-menu-horizontal:before {
  display: none;
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.gx-below-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.gx-above-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #8e929d;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #fa8c15;
  color: #fa8c15;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  content: '\e049';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: 'gaxon';
  font-size: 35px;
  line-height: 5px;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #f5f5f5;
}
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #003366;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -7px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fa8c15;
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  display: none;
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  display: none;
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: #fa8c15;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item svg {
  color: #003366;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span,
.gx-mini-custom-sidebar svg {
  color: #676c77;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title svg {
  margin-right: 0;
}
.gx-no-header-submenu-popup .ant-menu-sub {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}
#nav-bar-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a {
  color: #676c77;
}
#nav-bar-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected a {
  color: #fc5027;
  background-color: #fff5f0;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
.app-footer {
  font-size: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #c2cfd6;
  background: #f0f3f5;
  padding: 0 1rem;
  color: #151b1e;
  flex: 0 0 50px;
  display: flex;
}
.app-footer-product {
  margin-left: auto;
}
.app-footer-product span {
  margin-right: 5px;
}
.app-footer-product span a {
  color: #fc5027;
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 30px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: 'gaxon';
  font-size: 20px;
  content: '\30';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283d4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #ff2b7a;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3da4e6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469b9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #f18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6a95ff;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #f87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #a172e7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956fe7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: 'gaxon' !important;
  content: '\30';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fc5027;
  text-align: center;
  padding: 2px;
  color: #ffffff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  color: #038fdd;
}
.ant-drawer-content-wrapper {
  width: 256px !important;
}
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 236px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.boxed-layout .ant-drawer {
  max-width: 1272px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right,
.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer.ant-drawer-left,
  .boxed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (min-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    right: 20px;
    left: 20px;
    margin: 0;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
/*Login Styles*/
.gx-login-container {
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}
.gx-login-container h1 {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
}
.gx-login-container .card-body h1 {
  font-size: 2.1875rem;
  margin-bottom: 5px;
}
.gx-login-container .card-body .main-text {
  font-size: 44px;
}
.gx-login-container .card-body .secondary-text {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .gx-login-container .card-body .main-text {
    font-size: 36px;
  }
  .gx-login-container .card-body .secondary-text {
    font-size: 16px;
  }
}
.gx-login-container .card-body .login-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.gx-login-container .card-body .login-row a {
  font-weight: 500;
  font-size: 14px;
}
.gx-login-container .card-body .login-row .login-button {
  margin-bottom: 0px;
}
.gx-login-container .card-body .login-row .login-button span {
  font-weight: 500;
  font-size: 14px;
}
.gx-login-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
.gx-login-content {
  max-width: 520px;
  width: 100%;
  padding: 32px 32px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #e5e8ed;
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.gx-login-content p {
  color: #676c77;
  margin: 0;
  margin-bottom: 25px;
}
.gx-login-content a {
  color: #fc5027;
}
.gx-login-content .ant-form {
  margin-top: 10px;
}
.gx-login-content .ant-form .ant-form-item {
  margin-bottom: 25px;
}
.gx-login-content .ant-btn-round {
  background: #fc5027;
  color: #ffffff;
  border: #fc5027;
  font-weight: 500;
}
.gx-login-content .ant-input {
  background-color: #ffffff;
  height: auto;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #fc5027;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #ffffff;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #fc5027;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
  font-size: 20px;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(252, 80, 39, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #fc5027;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fc5027;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #fc5027;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
.login-logo {
  position: relative;
  z-index: 5;
  display: flex;
  margin-bottom: 40px;
}
.ant-input-group-addon {
  background: #ffffff;
}
.maintenance-content {
  max-width: 710px;
  width: 100%;
  position: relative;
  z-index: 5;
  text-align: center;
}
.users {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.users .user-details {
  width: 100%;
  padding: 20px;
  background: white;
}
.users-content {
  padding: 0 16px 16px;
}
.users-content .users-search {
  margin-bottom: 20px;
}
.users-content .users-table .ant-table-thead > tr > th {
  background: #ffffff;
  color: #676c77;
  font-weight: 500;
  padding: 16px 24px;
}
.users-card {
  overflow-y: auto;
  width: 100%;
}
.users-card .ant-card-bordered {
  margin-bottom: 10px;
}
.users-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.users-card .card-header h1 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 8px;
}
.users-card .card-content p {
  margin-bottom: 0;
  margin: 0;
  color: #676c77;
}
.organization {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
#organization-add-search .search-component {
  margin-right: 16px;
}
#add-search-organization .organization-search {
  margin-right: 5px;
}
.organization-content {
  padding: 0 16px 16px;
}
.organization-content .organization-table .ant-table-thead > tr > th,
.organization-content .organization-table .ant-table-cell .ant-table-column-has-sorters {
  background: #ffffff;
  color: #676c77;
  font-weight: 500;
  padding: 16px 24px;
}
.organization-content .organization-table .organization-card {
  height: calc(100vh - 187px);
  overflow-y: auto;
}
.organization-content .organization-table .organization-card .ant-card-bordered {
  margin-bottom: 10px;
}
.organization-content .organization-table .organization-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.organization-content .organization-table .organization-card .card-header h1 {
  margin: 0px;
  font-size: 18px;
}
.organization-content .organization-table .organization-card .card-header span {
  font-size: 12px;
}
.organization-content .organization-table .organization-card .ant-btn {
  height: 34px;
  margin-right: 10px;
  padding: 0px;
}
.organization-content .organization-table .organization-card .card-content p {
  margin: 0;
  margin-bottom: 2px;
  color: #676c77;
  line-height: 1.3;
}
.add-organization-header {
  background: #e5e8ed;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.add-organization-header svg {
  margin-bottom: 15px;
  margin-right: 10px;
}
.form-content {
  padding: 0px 24px 24px 24px;
}
@media screen and (max-width: 575px) {
  .form-content {
    padding: 0 20px 20px;
  }
}
.organization-form {
  display: flex;
  justify-content: center;
}
.organization-form .add-organization-form {
  width: 100%;
}
.organization-form .add-organization-form .ant-row.add-organization-form {
  display: flex;
  justify-content: flex-start;
}
.organization-form .add-organization-form .add-organization-content {
  width: 100%;
  padding: 20px 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.organization-form .add-organization-form .add-organization-content h2 {
  font-weight: 500;
  color: #202a3f;
}
.organization-form .add-organization-form .add-organization-content .organization-details,
.organization-form .add-organization-form .add-organization-content .owner-details {
  margin-bottom: 20px;
}
.organization-form .add-organization-form .add-organization-content .ant-form-item-control-input .ant-form-item-control-input-content > input {
  line-height: 1.3;
  height: 32px;
}
.organization-form .add-organization-form .add-organization-content .ant-select-selector {
  height: 36px;
  background: #f1f3f7;
}
.organization-form .add-organization-form .add-organization-content .ant-select-selector input {
  height: 100%;
}
.organization-form .add-organization-form .add-organization-content .addon-div {
  background: #f1f3f7;
  padding: 4px 8px;
  border-radius: 6px;
  align-items: center;
  color: #676c77;
  line-height: 1.5;
}
.organization-form .add-organization-form .add-organization-content .addon-div .ant-switch-checked {
  background: #202a3f;
}
.organization-form .add-organization-form .add-organization-content .addon-div .ant-row,
.organization-form .add-organization-form .add-organization-content .addon-div .ant-form-item {
  margin: 0;
}
.organization-form .add-organization-form .add-organization-content .font-weight-500 {
  font-weight: 500;
}
.organization-form .add-organization-form .add-organization-content .m-5 {
  margin: 5px;
}
.organization-form .add-organization-form .add-organization-content .sub-addon-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
}
.organization-form .add-organization-form .add-organization-content .sub-addon-div .input-number {
  width: 60px;
}
.organization-form .add-organization-form .add-organization-content .sub-addon-div .input-number input {
  background-color: #ffffff;
}
.organization-form .add-organization-form .add-organization-content .register-limit-input {
  width: 100px;
}
.organization-form .add-organization-form .add-organization-content .number-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cdd5e0;
  border-radius: 71px;
}
.organization-form .add-organization-form .add-organization-content .number-button.ant-btn-circle {
  min-width: 25px;
  height: 25px;
}
.organization-form .add-organization-form .add-organization-content .ant-input,
.organization-form .add-organization-form .add-organization-content .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-input-number-input,
.organization-form .add-organization-form .add-organization-content .ant-picker {
  background: #f1f3f7;
}
.organization-form .add-organization-form .add-organization-content .ant-card {
  border: 1px solid #e8e8e8;
}
.organization-form .add-organization-form .add-organization-content .ant-card .ant-card-body h1 {
  margin-top: 0;
  margin-bottom: 14px;
}
.organization-form .add-organization-form .add-organization-content .ant-card .ant-card-body .plan-details h3 {
  margin-top: 20px;
  margin-bottom: 8px;
}
.organization-form .add-organization-form label {
  font-size: 14px;
}
.organization-form .add-organization-form input::placeholder {
  color: #aeb3bb;
}
.organization-form .add-organization-form .ant-select-selection-item {
  color: #676c77;
}
.organization-form .add-organization-form .ant-form-item {
  margin-bottom: 10px;
}
.ant-picker {
  width: 100%;
}
.ant-tag {
  border-radius: 10px;
}
.inactive-tag {
  background: #e5e8ed;
  color: #676c77;
  font-weight: 500;
}
.active-tag {
  background: #d1fae5;
  color: #065f46;
  font-weight: 500;
  font-size: 12px;
}
.ant-table .ant-table-tbody .ant-btn {
  height: 34px;
  margin-right: 10px;
  padding: 0px;
}
.action {
  margin-left: auto;
}
.action .edit-button-disabled svg path {
  fill: #adb0b7;
}
.details-content {
  padding: 20px 20px;
}
@media screen and (max-width: 575px) {
  .details-content {
    padding: 0 16px 16px;
  }
}
.details-content .ant-tabs-ink-bar {
  background: #fc5027;
}
.details-content .ant-tabs-nav {
  margin: 0;
  border: none;
}
.details-content .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0;
}
@media screen and (max-width: 991px) {
  .details-content .ant-tabs-tabpane {
    height: max-content;
  }
}
.details-content .ant-tabs-tabpane .ant-descriptions-item-label {
  font-size: 14px;
  color: #676c77;
}
.details-content .ant-tabs-tabpane .ant-descriptions-item-content {
  color: #202a3f;
}
.details-content .ant-tabs-tabpane .addon-div {
  background: #f1f3f7;
  padding: 4px 8px;
  border-radius: 6px;
  color: #676c77;
}
.details-content .ant-tabs-tabpane .addon-div .ant-switch-checked {
  background: #202a3f;
}
.details-content .ant-tabs-tabpane .font-weight-500 {
  font-weight: 500;
}
.details-content .ant-tabs-tabpane .m-5 {
  margin: 5px;
}
.details-content .ant-tabs-tabpane .sub-addon-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  min-height: 36px;
}
.details-content .ant-tabs-tab {
  width: 96px;
  display: flex;
  margin: 0;
  border-radius: 4px;
  justify-content: center;
  background: #e5e8ed;
}
.details-content .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 500;
}
.details-content .ant-tabs-tab-active {
  background: #ffffff;
  color: #fc5027;
}
.details-content .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fc5027;
  font-weight: 500;
}
.details-content .ant-tabs-tab:hover {
  color: #fc5027;
}
.details-content .ant-tabs-tabpane {
  border-radius: 0px 8px 8px 8px;
}
.details-content .details-tab {
  background-color: transparent;
  padding: 0px;
}
.details-content .ant-tabs-top > .ant-tabs-nav::before,
.details-content .ant-tabs-bottom > .ant-tabs-nav::before,
.details-content .ant-tabs-top > div > .ant-tabs-nav::before,
.details-content .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}
.user-details .ant-table-thead > tr > th {
  color: #676c77;
}
.user-details .users-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.user-details .users-header-title {
  margin: 0;
}
.user-details .users-header .ant-btn-round {
  background: #fc5027;
  border-radius: 40px;
  border: #fc5027;
  font-weight: 500;
  font-size: 14px;
}
.user-details .user-table {
  margin-top: 15px;
}
.user-details .user-table .ant-table-thead > tr > th,
.user-details .user-table .ant-table-cell .ant-table-column-has-sorters {
  background: #ffffff;
  font-weight: 500;
  padding: 16px 24px;
}
.user-details .user-table .ant-pagination {
  margin-bottom: 0;
}
.users-card {
  height: calc(100vh - 187px);
  overflow-y: auto;
  margin-top: 15px;
  width: 100%;
}
.users-card .ant-card-bordered {
  margin-bottom: 10px;
}
.users-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.users-card .ant-btn.ant-btn-round.ant-btn {
  height: 34px;
  margin-right: 10px;
  padding: 0px;
}
.ant-table-wrapper tr > td.ant-table-cell {
  color: #222222;
  background: #ffffff;
  padding: 16px 24px;
}
.ant-table-wrapper tr > td.ant-table-cell .ant-tag {
  font-size: 12px;
}
.details h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}
.details p {
  margin: 0;
}
.organization-details h1 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
  margin-top: 0;
}
.ant-card-body {
  padding: 12px;
}
@media screen and (max-width: 991px) {
  .details _ .details-header {
    margin-left: 25px;
  }
}
@media screen and (max-width: 1000px) {
  .details-content-organization th.ant-descriptions-item,
  .details-content-subscription th.ant-descriptions-item {
    padding: 0;
  }
  .details-content-organization th.ant-descriptions-item .ant-descriptions-item-label,
  .details-content-subscription th.ant-descriptions-item .ant-descriptions-item-label {
    font-size: 12px;
    color: #676c77;
  }
}
.custom-date-picker {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: none;
  width: 245px;
  height: 32px;
}
.custom-date-picker .ant-picker-active-bar {
  background: none;
}
.custom-date-picker svg {
  width: 25px;
  fill: #fc5027;
  height: 25px;
  margin-right: 0px;
}
.custom-date-picker input {
  line-height: 1;
}
#root {
  height: 100vh;
}
.header-notification {
  display: flex;
  align-items: center;
}
.header-notification > * {
  margin-right: 20px;
}
.header-notification > .notification-bell {
  font-size: 20px;
  cursor: pointer;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table-pagination.ant-pagination {
  margin-bottom: 0;
  align-items: center;
}
.event-header-image .ant-upload-select-picture-card {
  width: 100%;
}
.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.event-form .ant-form-item-label {
  padding: 0;
  margin: 0;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content > .ant-message-custom-content .ant-message-info {
  height: 36px;
  display: flex;
  align-items: center;
}
.ant-message-notice-content .ant-message-custom-content {
  font-size: 16px;
}
.icon-btn {
  display: flex;
  align-items: center;
}
.upload-image-container .ant-upload-select-picture-card {
  width: 100%;
}
.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.custom-icons-list {
  margin-right: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border: none !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
  margin: -1px 20px 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 24px;
}
.login-content {
  padding: 35px;
  width: 100%;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-password {
  text-align: center;
}
.ant-btn-sm {
  line-height: 0;
}
.attendee-details .ant-form-item {
  min-width: 215px;
  margin-right: 16px;
}
.rsvp-modal .ant-modal-footer div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input:focus {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset;
}
.option-input:hover {
  border-color: transparent;
  border-right-width: 0;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.pointer {
  cursor: pointer;
}
.donation-list {
  overflow: auto;
}
.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}
.donation-card.active {
  background-color: #efefef;
}
.ministry-modal {
  height: 500px;
}
.ministry-modal .ant-modal-body {
  padding: 0;
}
.ministry-modal object {
  min-height: 500px;
}
.dialog .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog .ant-modal-footer > button:first-child {
  margin-right: 0px;
}
.action-icons {
  display: flex;
  align-items: center;
}
.action-icons button:first-child {
  margin-right: 12px;
}
.action-icons > button {
  margin-bottom: 0;
}
.break-word {
  word-break: break-word;
}
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-25 {
  margin-right: 25px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-15 {
  margin-left: 15px;
}
.fs-x-large {
  font-size: x-large;
}
.fs-14 {
  font-size: 14px;
}
.p-0 {
  padding: 0;
}
.p-16 {
  padding: 16px;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.vertically-middle {
  vertical-align: middle;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-vertical {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-self-end {
  align-self: flex-end;
}
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-100 {
  width: 100%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.fill-width {
  width: 100%;
}
.width-200 {
  width: 200px;
}
.fill-height {
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.b-0 {
  border: none;
}
.ant-popover-title {
  display: flex;
  justify-content: center;
}
.gx-user-popover li span {
  margin-right: 15px;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
}
.form-buttons button {
  margin-right: 10px;
  border: 1px solid #e8e8e8;
  font-weight: 500;
}
.form-buttons .cancel-button {
  background: #f2f4f7;
  border-radius: 40px;
  color: #676c77;
  border-color: #f2f4f7;
}
.form-buttons .save-button {
  background: #fc5027;
  color: #ffffff;
}
.gx-login-content .ant-form .ant-form-item:first-of-type {
  margin-bottom: 10px;
}
.set-password-form .password-input .ant-form-item-explain,
.set-password-form .password-input .ant-form-item-explain-connected {
  display: none;
}
.password-text {
  width: 100%;
  margin: 0px 0px 25px 0px;
}
.password-text .ant-checkbox-group {
  flex-direction: column;
}
.password-text .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d1fae5;
}
.password-text .ant-checkbox-disabled + span {
  color: #676c77;
}
.password-text .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  display: flex;
  margin: 0px;
}
.change-password-content {
  display: flex;
  justify-content: center;
}
.change-password-inside-content {
  max-width: 600px;
  width: 94%;
  margin-top: 25px;
  padding: 20px 20px 20px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.change-password-inside-content h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-bottom: 10px;
}
.change-password-inside-content #change-password .ant-row.ant-form-item {
  margin-bottom: 10px;
}
.change-password-inside-content .form-buttons {
  margin-top: 15px;
}
.change-password-inside-content .form-buttons .cancel-button:hover {
  background-color: #f2f4f7;
  color: #676c77;
  border-color: #f2f4f7;
}
.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 5px;
  font-size: 12px;
}
.ant-btn,
.ant-tag {
  border: none;
  box-shadow: none;
}
.wrapper-404 {
  min-height: calc(100vh - 50px - 72px - 40px);
  min-width: auto;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-404 .error-msg {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 6px;
}
.wrapper-404 .home-link {
  font-size: 20px;
}
.ant-input,
.ant-select-selection-placeholder,
.ant-input-number-input,
.ant-picker-input > input {
  font-size: 14px;
}
.instruction-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 200px);
  min-height: 400px;
  overflow-x: hidden;
}
.instruction-scroll-wrapper.image-list .infinite-data-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  width: auto;
}
.gallery-image-popover .ant-popover-content {
  max-width: 310px;
  height: auto;
}
.gallery-image-popover .ant-popover-content .ant-popover-arrow {
  display: none;
}
.gallery-image-popover .ant-popover-content .ant-popover-inner {
  background: #f2f4f7;
}
.gallery-image-popover .ant-popover-content .ant-popover-inner .ant-descriptions-item-label,
.gallery-image-popover .ant-popover-content .ant-popover-inner .ant-descriptions-item-content {
  font-size: 16px;
}
.gallery-image-popover .ant-popover-content .ant-popover-inner .ant-descriptions-item-content {
  color: #202a3f;
}
.gallery-image {
  width: calc(20% - 16px);
  margin: 0 8px;
  position: relative;
}
.gallery-image .ant-image-mask:hover {
  opacity: 0;
}
.gallery-image:hover .info-button {
  opacity: 1;
}
@media screen and (max-width: 575px) {
  .gallery-image {
    width: calc(40% - 16px);
  }
}
@media screen and (min-width: 1440px) {
  .gallery-image {
    width: calc(20% - 130px);
  }
}
.gallery-image .ant-popover-arrow {
  display: none;
}
.gallery-image .ant-image {
  margin: 0 0 16px 0;
  width: 100%;
}
.gallery-image .ant-image .ant-image-img {
  border-radius: 8px;
  object-fit: cover;
}
.gallery-image .ant-btn-icon-only {
  height: 24px;
  width: 24px;
  padding: 3px 0;
}
.gallery-image .info-button {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}
@media screen and (max-width: 999px) {
  .gallery-image .info-button {
    opacity: 1;
  }
}
.infinite-loader {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.8);
}
.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
.action {
  margin-left: auto;
}
.action svg circle {
  fill: #ffffff;
}
td.ant-table-cell {
  word-break: break-all;
}
.break-all-words {
  word-break: break-all;
}
.ant-typography {
  color: #000000;
}
.text-primary {
  color: #fc5027;
}
.text-secondary {
  color: #676c77;
}
.expand-text.ant-typography {
  margin-bottom: 0;
}
.ant-typography,
.ant-typography-ellipsis,
.expand-text {
  white-space: pre-wrap;
  word-break: break-word;
}
.disabled-button svg path {
  fill: #676c77;
}
.tag-beta.ant-tag {
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
}
.position-relative {
  position: relative;
}
.collapsible-filter-wrapper .ant-collapse,
.collapsible-filter-wrapper .ant-collapse-item,
.collapsible-filter-wrapper .ant-collapse-content {
  border: none;
}
.collapsible-filter-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: none;
}
.collapsible-filter-wrapper .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.collapsible-filter-wrapper .ant-col:nth-of-type(2n) {
  padding: 0;
}
.collapsible-filter-wrapper .ant-col:nth-of-type(2n + 1) {
  padding-left: 0;
}
.collapsible-filter-wrapper .ant-input,
.collapsible-filter-wrapper .ant-input-affix-wrapper,
.collapsible-filter-wrapper .common-dropdown-wrapper .ant-select .ant-select-selector {
  border-color: #e8e8e8;
  height: 32px;
  display: flex;
  align-items: center;
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 276px);
  min-height: 400px;
}
.common-dropdown-wrapper .ant-select.ant-select-single .ant-select-selector {
  border-radius: 40px;
}
.common-dropdown-wrapper .ant-spin-blur {
  opacity: 1;
}
.infinite-data-container {
  top: 0;
}
.infinite-data-container .ant-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.skeleton-card {
  border: 1px solid #e8e8e8;
  padding: 16px;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #676c77;
  background: #ffffff;
  border-color: #e5e8ed;
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel:last-child {
    width: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header {
    position: absolute;
    right: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
    visibility: hidden;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-body {
    display: none;
  }
  .custom-date-picker-panel .ant-picker-footer-extra {
    line-height: 18px;
    padding: 8px 12px;
  }
}
.ant-picker-dropdown .ant-picker-header-view {
  font-weight: 500;
  color: #535353;
}
.custom-date-picker-panel .ant-picker-panel-layout {
  flex-direction: column-reverse;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets {
  max-width: 100%;
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets {
    max-width: 288px;
  }
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul {
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: none;
  border-top: var(--ant-line-width) var(--ant-line-type) var(--ant-color-split);
  gap: 16px;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul li {
  font-size: 12px;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul li + li {
  margin: 0;
}
.filter-btn.ant-btn {
  border: none;
  padding: 0;
  background-color: #202a3f;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.has-select-all .ant-select-selector {
  max-height: 200px;
  overflow: auto;
}
.no-tag-render .ant-select-selection-item {
  display: none;
}
.image-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #676c77;
}
.gallery-info {
  z-index: 9999;
}
.gallery-info .ant-popover-arrow {
  display: none;
}
.gallery-info .ant-popover-inner-content {
  padding: 12px 16px;
}
.text-danger {
  color: #f44336;
}
.note-margin {
  margin: -10px 0 10px 0;
  font-size: 14px;
}
.country-code-phone-input .ant-input-group-addon:first-child {
  padding: 0;
}
.country-code-phone-input .ant-input {
  padding: 5px 10px;
}
.country-code-phone-input .country-code-input {
  width: 80px;
}
.country-code-phone-input .country-code-input.ant-form-item {
  margin-bottom: 0;
}
.country-code-phone-input .country-code-input .ant-form-item-control-input {
  min-height: 30px;
}
.country-code-phone-input .country-code-input .ant-select.ant-select-single {
  width: 100%;
}
.country-code-phone-input .country-code-input .ant-select-selector {
  padding: 0;
}
.ant-pagination li:not(.ant-pagination-options) {
  border: 1px solid #d9d9d9;
}
.ant-pagination li.ant-pagination-item-active {
  border: 1px solid #ff784f;
}
.ant-modal .ant-modal-content {
  padding: 0;
}
.ant-modal .ant-modal-content .ant-modal-header {
  padding: 16px 24px;
  color: #676c77;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
  margin: 0;
}
.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: 500;
}
.ant-modal .ant-modal-content .ant-modal-body {
  padding: 24px;
  color: #676c77;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
  margin: 0;
}
.ant-modal .ant-modal-content .ant-modal-body p {
  margin-top: 0;
}
.ant-modal .ant-modal-content .ant-modal-body h2 {
  margin: 0;
  margin-bottom: 10px;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-popover-content .ant-popover-inner {
  padding: 0;
}
.bg-transparent {
  background: transparent;
}
.ant-picker {
  padding: 7px 11px;
}
.ant-picker .ant-picker-input > input {
  font-size: 14px;
  height: 18px;
}
.mobile-layout {
  flex: 1 1;
}
[hidden] {
  display: none;
}
.not-found .gx-error-code {
  color: #676c77;
}
.not-found .gx-btn-primary {
  color: #fc5027;
}
.header-portal-wrapper .buttons #header-right-content .form-buttons > button:nth-child(1):hover {
  background-color: transparent;
  color: #676c77;
}
.header-portal-wrapper .buttons #header-right-content .form-buttons > button:nth-child(2):hover {
  background-color: #fc5027;
  color: #ffffff;
}
::selection {
  background: #fc5027;
  color: white;
}
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomRight .ant-picker-range-arrow {
  display: none;
}
.ant-tooltip .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.75);
}
.nested-addon-div {
  margin: 0 10px 10px 20px;
  font-size: 14px;
}
