/*Login Styles*/

@text-color: #676c77;

.@{class-prefix}-login-container {
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  .flex-only(1);

  .flex-display(flex, column, wrap);
  .align-items(center);
  justify-content: center;
  & h1 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
  }
  & .card-body {
    & h1 {
      font-size: 2.1875rem;
      margin-bottom: 5px;
    }
    .main-text {
      font-size: 44px;
    }
    .secondary-text {
      font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      .main-text {
        font-size: 36px;
      }
      .secondary-text {
        font-size: 16px;
      }
    }
    & .login-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      & a {
        font-weight: 500;
        font-size: @font-size-base - 2px;
      }
      .login-button {
        margin-bottom: 0px;
        span {
          font-weight: 500;
          font-size: @font-size-base - 2px;
        }
      }
    }
  }
}

.@{class-prefix}-login-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}

.@{class-prefix}-login-content {
  max-width: 520px;
  width: 100%;
  padding: 32px 32px;
  background-color: @white-color;
  .border-radius(@border-radius-lg);
  border: 1px solid @light-white-color;
  font-size: 14px;
  position: relative;
  z-index: 5;

  & p {
    color: @text-color;
    margin: 0;
    margin-bottom: 25px;
  }
  & a {
    color: @primary-color;
  }

  & .ant-form {
    margin-top: 10px;
    & .ant-form-item {
      margin-bottom: 25px;
    }
  }

  & .ant-btn-round {
    background: @primary-color;
    color: @white-color;
    border: @primary-color;
    font-weight: 500;
  }

  & .ant-input {
    background-color: @white-color;
    height: auto;

    &:focus {
      box-shadow: none;
      border-color: @primary-color;
    }
  }

  & .ant-input-affix-wrapper {
    background-color: @white-color;
    max-height: none;
  }

  & .ant-form-item-control-wrapper {
    width: 100%;
  }

  & .ant-form-item-children {
    display: block;
  }

  @media screen and (max-width: @screen-xs-max) {
    padding: 20px 20px 10px;
  }
}

.@{class-prefix}-login-header {
  margin-bottom: 30px;

  @media screen and (max-width: @screen-xs-max) {
    margin-bottom: 15px;
  }
}

.@{class-prefix}-app-login-wrap {
  height: 100%;
  .flex-display(flex, column, nowrap);
  .justify-content(center);
  overflow-x: hidden;

  @media screen and (max-width: @screen-xs-max) {
    padding-top: 20px;
    .justify-content(flex-start);
  }
}

.@{class-prefix}-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  @media screen and (max-width: @screen-xs-max) {
    padding-bottom: 20px;
  }

  & .@{class-prefix}-loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.@{class-prefix}-app-login-main-content {
  .flex-display(flex, row, wrap);
  background-color: @white-color;
  .box-shadow(@gx-card-shadow);
  .border-radius(@border-radius-lg + 2px);
  font-size: 14px;
  overflow: hidden;
}

.@{class-prefix}-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;

  & .ant-input {
    background-color: @grey-3;

    &:focus {
      box-shadow: none;
      border-color: @primary-color;
    }
  }

  & .@{class-prefix}-btn {
    padding: 6px 35px !important;
    height: auto;
  }

  & .ant-form-item-control-wrapper {
    width: 100%;
  }

  & .ant-form-item-children {
    display: block;
  }

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    padding: 20px 20px 10px;
  }
}

.@{class-prefix}-app-login-header {
  margin-bottom: 30px;

  @media screen and (max-width: @screen-xs-max) {
    margin-bottom: 15px;
  }
}

.@{class-prefix}-app-logo-content {
  color: @white-color;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  .flex-display(flex, column, nowrap);

  & > * {
    position: relative;
    z-index: 2;
  }

  & h1 {
    color: @white-color;
    font-size: @h2-font-size;
  }

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    padding: 20px 20px 10px;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      right: 0;
      bottom: 0;
      background-color: fade(@primary-color, 70%);
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.@{class-prefix}-app-logo-wid {
  margin-bottom: auto;
}

.@{class-prefix}-app-social-block {
  .flex-display(flex, row, wrap);
  .align-items(center);
  .justify-content(space-between);

  & .@{class-prefix}-social-link,
  & .@{class-prefix}-social-link li {
    margin: 0;
  }

  & .@{class-prefix}-social-link span {
    border: @border-style-base @border-width-base @primary-color;
    .border-radius(@border-radius-circle);
    color: @primary-color;

    &:hover,
    &:focus {
      color: @white-color;
      background-color: @primary-color;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.@{class-prefix}-signin-form {
  & .ant-form-item-control-input {
    min-height: 40px;
  }
}

.login-logo {
  position: relative;
  z-index: 5;
  display: flex;
  margin-bottom: 40px;
}

.ant-input-group-addon {
  background: @white-color;
}
.maintenance-content {
  max-width: 710px;
  width: 100%;
  position: relative;
  z-index: 5;
  text-align: center;
}
